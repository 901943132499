<template>
  <app-layout v-if="$route.meta.middleware">
    <router-view />
  </app-layout>
  <div v-else>
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  computed: {},

  beforeCreate() {},

  methods: {},
};
</script>
